import instance from '../configAxios';

export const getListCliente = async () => {
  const axios = await instance();

  try {
    const response = await axios.post(`/cliente/listagem/contador`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getClienteById = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/cliente/${id}`)
    return { sucesso: response.status, data: response.data }
  } catch (error) {
    return { sucesso: false, mensagem: error.response.data };
  }
}