import React, { useState, useEffect } from 'react'
import { IdentificationIcon, LocationMarkerIcon, MailIcon, ReceiptTaxIcon } from '@heroicons/react/solid'
import { getListCliente } from '../../services/cliente.js'
import { downloadArquivo } from '../../services/arquivo'
import { getUsuarioLogado } from '../../services/usuario'
import { getContadorById } from '../../services/contador'
import { Link } from "react-router-dom";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import { toast } from 'react-toastify';
import API_URL from '../../utils/api'
import { UserAddIcon } from '@heroicons/react/outline'

export default function ClienteListagem() {
    const [listaCliente, setListaCliente] = useState([])

    const [loading, setLoading] = useState(true)
    const [usuario, setUsuario] = useState()
    const [contador, setContador] = useState()

    const documentoMask = (d, tipoPessoa) => {
        if (tipoPessoa === "PF" || d.length <= 11) {
            return d.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (tipoPessoa === "PJ" || d.length > 11) {
            return d.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, '$1.$2.$3/$4-$5');
        }
    }

    const CardCliente = (props) => {
        const {
            id,
            celular,
            email,
            arquivoLogoId,
            nomeFantasia,
            razaoSocial,
        } = props.dadosLinha

        const celularMask = (c) => {
            if (c.length < 11) {
                return c.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
            } else {
                return c.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
            }
        }

        return (
            <li
                key={id}
                className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
            >
                <div className="flex-1 flex flex-col p-8">
                    <img className="w-32 h-32 object-contain flex-shrink-0 mx-auto rounded-full"
                        src={arquivoLogoId ? `${API_URL}/arquivo/download/${arquivoLogoId}` : require('../../assets/images/user.png')}
                        alt="Cliente" />
                    <h3 className="mt-6 text-gray-900 text-sm font-medium">{nomeFantasia}</h3>
                    <dl className="mt-1 flex-grow flex flex-col justify-between">
                        <dt className="sr-only">Nome</dt>
                        <dd className="text-gray-500 text-sm">{email}</dd>
                        <dd className="text-gray-500 text-sm">{celular && celularMask(celular)}</dd>
                        <dt className="sr-only">Razão Social</dt>
                        <dd className="mt-3">
                            <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                {razaoSocial}
                            </span>
                        </dd>
                    </dl>
                </div>
                <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="w-0 flex-1 flex">
                            <Link
                                to="/documentos-fiscais" state={{ "idCliente": id }}
                                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                            >
                                <ReceiptTaxIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">Documentos Fiscais</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        carregaClientes()
        carregaUsuario()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const carregaClientes = async () => {
        const response = await getListCliente()

        if (response.sucesso) {
            setListaCliente(response.data)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const renderCardsClientes = () => {
        return listaCliente.map((dadosLinha, key) => {
            return <CardCliente key={key} dadosLinha={dadosLinha} />
        })
    }

    async function carregaUsuario() {
        setLoading(true)
        const response = await getUsuarioLogado()

        if (response.sucesso) {
            setUsuario(response.data)
            if (response.data.arquivoFoto) carregaFotoUsuario(response.data.arquivoFoto.id)
            if (response.data.idContador) carregaContador(response.data.idContador)
            setLoading(false)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaFotoUsuario(id) {
        setLoading(true)
        const response = await downloadArquivo(id)

        if (response.sucesso) {
            setLoading(false)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaContador(id) {
        setLoading(true)
        const response = await getContadorById(id)

        if (response.sucesso) {
            setContador(response.data)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <>
                {!listaCliente ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <EmptyPage texto="Nenhuma Cliente Cadastrado" Icone={UserAddIcon} />
                        </div>
                    </div>
                    :
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pt-4">

                        <div className="bg-white shadow rounded-lg">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                <div className="flex-1 grid lg:grid-cols-2 grid-cols-1 gap-4 lg:divide-x divide-gray-200 min-w-0 py-6">
                                    {/* Dados Contador */}
                                    <div className="flex px-6">
                                        <img
                                            className="object-contain hidden h-16 w-16 rounded-full sm:block"
                                            src={contador?.arquivoLogoId ? `${API_URL}/arquivo/download/${contador?.arquivoLogoId}` : require('../../assets/images/user.png')}
                                            alt={"Foto contador"}
                                        />
                                        <div>
                                            <div className="flex items-center truncate">
                                                <img
                                                    className="object-contain h-16 w-16 rounded-full sm:hidden"
                                                    src={contador?.arquivoLogoId ? `${API_URL}/arquivo/download/${contador?.arquivoLogoId}` : require('../../assets/images/user.png')}
                                                    alt={"Foto contador"}
                                                />
                                                <div className='grid grid-cols-1 gap-1'>
                                                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 truncate">
                                                        {contador?.nomeFantasia}
                                                    </h1>
                                                    <h1 className="ml-3 text-lg font-bold leading-7 text-gray-900 sm:leading-9 truncate">
                                                        {contador?.razaoSocial}
                                                    </h1>
                                                </div>
                                            </div>
                                            <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                                <dt className="sr-only">Contador</dt>
                                                <dd className="flex items-center text-sm text-gray-500 font-medium sm:mr-6">
                                                    <MailIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    {usuario?.email}
                                                </dd>

                                                <dd className="flex items-center text-sm text-gray-500 font-medium sm:mr-6">
                                                    <IdentificationIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    {contador && documentoMask(contador.documento, contador.tipoPessoa)}
                                                </dd>

                                                <dd className="flex items-center text-sm text-gray-500 font-medium sm:mr-6">
                                                    <LocationMarkerIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    {contador?.municipio}, {contador?.uf}
                                                </dd>

                                            </dl>
                                        </div>
                                    </div>
                                    {/* Dados Usuário */}
                                    <div className="flex px-6">
                                        <img
                                            className="object-contain hidden h-16 w-16 rounded-full sm:block"
                                            src={usuario?.arquivoFoto ? `${API_URL}/arquivo/download/${usuario?.arquivoFoto.id}` : require('../../assets/images/user.png')}
                                            alt={"Foto usuario"}
                                        />
                                        <div>
                                            <div className="flex items-center truncate">
                                                <img
                                                    className="object-contain h-16 w-16 rounded-full sm:hidden"
                                                    src={usuario?.arquivoFoto ? `${API_URL}/arquivo/download/${usuario?.arquivoFoto.id}` : require('../../assets/images/user.png')}
                                                    alt={"Foto usuario"}
                                                />
                                                <div className='grid grid-cols-1 gap-1'>
                                                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 truncate">
                                                        {usuario?.nome}
                                                    </h1>
                                                </div>
                                            </div>
                                            <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                                <dt className="sr-only">Contador</dt>
                                                <dd className="flex items-center text-sm text-gray-500 font-medium sm:mr-6">
                                                    <MailIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    {usuario?.email}
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="overflow-hidden sm:rounded-lg py-8">
                            <div className="flex items-center truncate pt-4 pb-2">
                                <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 truncate">
                                    Clientes
                                </h1>
                            </div>
                            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                                {renderCardsClientes()}
                            </ul>
                        </div>
                    </div>
                }
            </>
        )
    }
}