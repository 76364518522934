import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

export default function LayoutPaginacaoTabela(props) {
  const {
    pagina,
    totalPaginas,
    totalRegistros,
    registrosPorPagina,
    onClickPaginaAnterior,
    onClickPaginaPosterior,
    onClickPagina,
    className
  } = props

  const registroInicialAtual = pagina * registrosPorPagina + 1
  const registroFinalAtual = (pagina + 1) * registrosPorPagina > totalRegistros? totalRegistros : (pagina + 1) * registrosPorPagina

  const renderBotaoPaginaAnterior = (posicao, onClickPagina) => (
    <button
      onClick={() => onClickPagina(pagina - posicao)}
      className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
    >
      {pagina - posicao + 1}
    </button>
  )

  const renderBotaoPaginaPosterior = (posicao, onClickPagina) => (
    <button
      onClick={() => onClickPagina(pagina + posicao)}
      className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
    >
      {pagina + posicao + 1}
    </button>
  )

  return (
    <div className={`bg-white px-4 py-3 flex items-center border-t border-gray-200 justify-between sm:px-6 ${className}`}>
      <div className="flex-1 flex justify-between sm:hidden">
        {pagina > 0 && (  
          <button
            onClick={onClickPaginaAnterior}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Anterior
          </button>
        )}

        {pagina + 1 < totalPaginas && (
          <button
            onClick={onClickPaginaPosterior}
            className="ml-3 relative inline-flex items-center ml-auto px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Próximo
          </button>
        )}
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Mostrando <span className="font-medium">{registroFinalAtual?registroInicialAtual:0}</span> até <span className="font-medium">{registroFinalAtual}</span> de{' '}
            <span className="font-medium">{totalRegistros}</span> resultados
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            {pagina > 0 && (
              <button
                onClick={onClickPaginaAnterior}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Anterior</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}

            {pagina > 1 && (
              <button
                onClick={() => onClickPagina(0)}
                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              >
                1
              </button>
            )}
            {pagina > 2 && (
              <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                ...
              </span>
            )}

            {pagina > 0 && renderBotaoPaginaAnterior(1, onClickPagina)}
            <span className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
              {pagina + 1}
            </span>
            {pagina + 1 < totalPaginas && renderBotaoPaginaPosterior(1, onClickPagina)}


            {pagina + 3 < totalPaginas && (
              <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                ...
              </span>
            )}
            {pagina + 2 < totalPaginas && (
              <button
                onClick={() => onClickPagina((totalPaginas) - 1)}
                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              >
                {totalPaginas}
              </button> 
            )}

            {pagina + 1 < totalPaginas && (
              <button
                onClick={onClickPaginaPosterior}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Próximo</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </nav>
        </div>
      </div>
    </div>
  )
}