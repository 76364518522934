import React from 'react'

export default function EmptyPage(props) {
  const {texto, Icone, acao, botao} = props
  return (
    <div className="relative block w-full py-8 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
      <Icone className="w-12 h-12 text-gray-400 inline-flex items-center" />
      <span className="mt-6 mb-8 block text-md font-medium text-green-900">{texto}</span>
      {botao && <button  
        onClick={acao}
        type="button" 
        className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
          Adicionar
      </button>}
    </div>
  )
}
