import React, { useState, useEffect, Fragment } from 'react'
import { baixarTodosArquivosCLiente, getListArquivoCliente, getListSelectTipoDocumentoArquivoCLiente, getListSelectTipoMovimentoArquivoCLiente, updateArquivoCliente } from '../../services/arquivoCliente.js'
import { useLocation, Link } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import ModalConfirmacao from "../../components/ModalConfirmacao";
import Loading from "../../components/Loading";
import { toast } from 'react-toastify';
import { UserAddIcon } from '@heroicons/react/outline'
import { CheckIcon, ChevronRightIcon, DocumentTextIcon, DotsVerticalIcon, DownloadIcon, EyeIcon, SwitchHorizontalIcon, UserIcon } from '@heroicons/react/solid';
import API_URL from '../../utils/api.js';
import Select from 'react-select';
import useDebounce from '../../hooks/useDebounce';
import { getClienteById } from '../../services/cliente.js';
import { Menu, Transition } from '@headlessui/react'
import SolicitacaoDownloadListagem from "../SolicitacaoDownload";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ArquivoClienteListagem() {
    var FileSaver = require('file-saver');
    const location = useLocation();

    let idCliente = location.state ? location.state.idCliente : null;

    const [cliente, setCliente] = useState(null);
    const [listaArquivoCliente, setListaArquivoCliente] = useState([])
    const [arquivoSelecionado, setArquivoSelecionado] = useState(null)
    const [baixandoArquivo, setBaixandoArquivo] = useState(false)
    const [baixandoTodos, setBaixandoTodos] = useState(false)

    //Filtros
    const [mesSelecionado, setMesSelecionado] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}`);
    const [filtroPeriodo, setFiltroPeriodo] = useState({ value: "competencia", label: "Competência" })
    const opcoesPeriodo = [
        { value: null, label: "Todas" },
        { value: 'emissao', label: "Emissão" },
        { value: 'competencia', label: "Competência" }
    ];
    const [opcoesTipoDocumento, setOpcoesTipoDocumento] = useState([]);
    const [filtroTipoDocumento, setFiltroTipoDocumento] = useState({ value: null, label: "Todas" })
    const [opcoesTipoMovimento, setOpcoesTipoMovimento] = useState([]);
    const [filtroTipoMovimento, setFiltroTipoMovimento] = useState({ value: null, label: "Todas" })

    const [confirmacaoDownloadTodos, setConfirmacaoDownloadTodos] = useState(false)

    const [loading, setLoading] = useState(true)

    const filtroDebounce = useDebounce(carregaArquivoClientes, 500)

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mesSelecionado, filtroPeriodo, filtroTipoDocumento, filtroTipoMovimento])

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = 10

    async function atualizaDownloadArquivo(id) {
        const response = await updateArquivoCliente(id);
        if (response.sucesso) {
            carregaArquivoClientes()
        }
        else {
            toast.error(response.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function downloadArquivoSelecionado() {
        if (baixandoTodos || baixandoArquivo) {
            toast.info(`Download em andamento`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        setBaixandoArquivo(true)

        try {
            FileSaver.saveAs(`${API_URL}/arquivo/download/${arquivoSelecionado.arquivoId}`, `${arquivoSelecionado.arquivo.nomeArquivo}${arquivoSelecionado.arquivo.extensao}`);

            toast.success(`Arquivo ${arquivoSelecionado.arquivo.nomeArquivo} baixado com suceso!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setBaixandoArquivo(false)

            atualizaDownloadArquivo(arquivoSelecionado.id)

            setTimeout(() => {
                setArquivoSelecionado(null)
            }, 1000);
        } catch (error) {
            toast.error(`Erro ao baixar arquivo`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBaixandoArquivo(false)
            setArquivoSelecionado(null)
        }

    }

    function handleDownloadTodos() {
        if (filtroPeriodo.value == null) {
            toast.error("Selecione um tipo de período para realizar essa operação", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }


        setConfirmacaoDownloadTodos(true)
    }

    async function downloadTodosAquivos() {
        setBaixandoTodos(true)

        const filtros = {
            clienteId: idCliente,
            periodo: new Date(mesSelecionado).toISOString().split("T")[0],
            tipoPeriodo: filtroPeriodo.value,
            tipoDocumento: filtroTipoDocumento.value,
            tipoMovimento: filtroTipoMovimento.value,
        }
        
        const response = await baixarTodosArquivosCLiente(filtros)

        if (response.sucesso) {
            setTimeout(() => {
                toast.success(`Download Solicitado com suceso!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setBaixandoTodos(false)
            }, 5000);
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setBaixandoTodos(false)
    }

    const carregaOpcoesTipoDocumento = async () => {
        setLoading(true)

        const response = await getListSelectTipoDocumentoArquivoCLiente()

        if (response.sucesso) {
            const lista = response.data.map(tipoDocumento => { return { value: tipoDocumento, label: tipoDocumento } })
            const opcaoNula = { value: null, label: 'Selecione' }
            setOpcoesTipoDocumento([opcaoNula, ...lista])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const carregaOpcoesTipoMovimento = async () => {
        setLoading(true)

        const response = await getListSelectTipoMovimentoArquivoCLiente()

        if (response.sucesso) {
            const lista = response.data.map(tipoMovimento => { return { value: tipoMovimento, label: tipoMovimento } })
            const opcaoNula = { value: null, label: 'Selecione' }
            setOpcoesTipoMovimento([opcaoNula, ...lista])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    useEffect(() => {
        if (arquivoSelecionado) downloadArquivoSelecionado()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arquivoSelecionado])


    const LinhaTabela = ({ dadosLinha }) => {
        const {
            tipoMovimento,
            chaveAcesso,
            tipoDocumento,
            dataEmissao,
            ultimoDownload,
            dataCompetencia,
            arquivoEnvioId,
            arquivoEnvio,
            arquivoRetornoId,
            arquivoRetorno,
            arquivoCancelamentoId,
            arquivoCancelamento,
            arquivoId,
            arquivo,
            documento,
            documentoSerie,
            protocolo,
            contingencia,
            status,
            motivo,
            ambiente,
        } = dadosLinha

        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-2 sm:px-6 py-4">
                    <div className="lg:grid-cols-6 grid grid-cols-1 gap-4">
                        <div className='lg:col-span-2'>
                            <div className='flex flex-row items-center text-gray-800 text-sm font-semibold truncate'>
                                <p className='truncate mr-1'>Documento:  </p>{documento}
                                {documentoSerie && <p className='text-gray-400 ml-1'>Série: {documentoSerie}</p>}
                            </div>
                            <div className='text-gray-500 text-sm flex flex-row space-x-1 items-center'>
                                <p className='truncate'>Chave: {chaveAcesso}</p>
                            </div>
                        </div>

                        <div>
                            {!arquivoId &&
                                <div className={classNames(status === 100 ? 'bg-green-200 text-green-600' : 'bg-red-200 text-red-600', 'mr-2 h-fit space-x-1 text-xs font-semibold rounded-md py-1 px-2 w-fit')}>
                                    <p>{status} - {motivo}</p>
                                </div>
                            }
                        </div>

                        <div className='text-sm'>
                            <p className='text-gray-800 text-sm font-semibold'>Protocolo: {protocolo}</p>
                            <div className='flex flex-row space-x-2'>
                                <span className={classNames(ambiente === "Produção" ? 'bg-blue-300 text-blue-800' : 'bg-purple-300 text-purple-800', 'text-xs font-semibold rounded-md py-1 px-2')}>
                                    {ambiente}
                                </span>
                                {contingencia &&
                                    <span className='text-xs font-semibold rounded-md py-1 px-2 bg-orange-300 text-orange-600 text-white'>
                                        Contingência
                                    </span>
                                }
                            </div>

                        </div>

                        <div className='lg:justify-self-center'>
                            <div className='text-gray-800 text-sm font-semibold flex flex-row items-center space-x-1'>
                                <SwitchHorizontalIcon className='w-4 h-4 text-gray-600' />
                                <p>{tipoMovimento}</p>
                            </div>
                            <div className='text-gray-500 text-sm font-semibold flex flex-row items-center space-x-1'>
                                <DocumentTextIcon className='w-4 h-4 text-gray-400' />
                                <p>{tipoDocumento}</p>
                            </div>
                        </div>

                        <div className="justify-between flex">
                            <div>
                                <p className='text-gray-800 text-sm font-semibold'>Emissão: {new Date(dataEmissao).getMonth() + 1 < 10 ? `0${new Date(dataEmissao).getMonth() + 1}` : new Date(dataEmissao).getMonth() + 1}/{new Date(dataEmissao).getFullYear()}</p>
                                <p className='text-gray-500 text-sm'>Competência: {new Date(dataCompetencia).getMonth() + 1 < 10 ? `0${new Date(dataCompetencia).getMonth() + 1}` : new Date(dataCompetencia).getMonth() + 1}/{new Date(dataCompetencia).getFullYear()}</p>
                            </div>
                            <Menu as="div" className="relative inline-block text-left self-center">
                                <div>
                                    <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-30 origin-top-right absolute divide-y-2 divide-gray-200 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {arquivoId && <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href={arquivo.dropboxLinkView}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Visualizar arquivo Pricipal</p>
                                                                <EyeIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </a>

                                                )}
                                            </Menu.Item>}
                                            {arquivoEnvio && <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href={arquivoEnvio.dropboxLinkView}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoEnvioId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Visualizar arquivo Envio</p>
                                                                <EyeIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoEnvioId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoEnvioId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </a>

                                                )}
                                            </Menu.Item>}
                                            {arquivoRetornoId && <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href={arquivoRetorno.dropboxLinkView}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoRetornoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Visualizar arquivo Retorno</p>
                                                                <EyeIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoRetornoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoRetornoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </a>

                                                )}
                                            </Menu.Item>}
                                            {arquivoCancelamentoId && <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href={arquivoCancelamento.dropboxLinkView}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoCancelamentoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Visualizar arquivo Cancelamento</p>
                                                                <EyeIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoCancelamentoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoCancelamentoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </a>

                                                )}
                                            </Menu.Item>}
                                        </div>

                                        <div className="py-1">
                                            {arquivoId && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => { setArquivoSelecionado(arquivo) }}
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Baixar arquivo Pricipal</p>
                                                                <DownloadIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                            {arquivoEnvio && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => { setArquivoSelecionado(arquivoEnvio) }}
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoEnvioId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Baixar arquivo Envio</p>
                                                                <DownloadIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoEnvioId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoEnvioId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                            {arquivoRetornoId && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => { setArquivoSelecionado(arquivoRetorno) }}
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoRetornoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Baixar arquivo Retorno</p>
                                                                <DownloadIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoRetornoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoRetornoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                            {arquivoCancelamentoId && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => { setArquivoSelecionado(arquivoCancelamento) }}
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoCancelamentoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Baixar arquivo Cancelamento</p>
                                                                <DownloadIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoCancelamentoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoCancelamentoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>

                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        carregaArquivoClientes(registrosPorPagina, paginaAtual)
        if (idCliente) carregaCliente()
        carregaOpcoesTipoDocumento()
        carregaOpcoesTipoMovimento()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idCliente])

    async function carregaArquivoClientes(pageSize = registrosPorPagina, currentPage = 0) {
        let filtros = {
            clienteId: idCliente,
            pageSize,
            periodo: new Date(mesSelecionado).toISOString().split("T")[0],
            tipoPeriodo: filtroPeriodo.value,
            tipoDocumento: filtroTipoDocumento.value,
            tipoMovimento: filtroTipoMovimento.value,
            currentPage,
        }

        const response = await getListArquivoCliente(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaArquivoCliente(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    async function carregaCliente() {
        const response = await getClienteById(idCliente)

        if (response.sucesso) {
            setCliente(response.data)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const renderLinhasTabela = () => {
        return listaArquivoCliente.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }

    else {
        return (
            <>
                {!listaArquivoCliente ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <EmptyPage texto="Nenhuma ArquivoCliente Cadastrado" Icone={UserAddIcon} />
                        </div>
                    </div>
                    :
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <nav aria-label="Breadcrumb" className='mt-4 flex'>
                            <ol className="inline-flex flex-wrap items-center space-x-1 md:space-x-3">
                                <li className="inline-flex items-center">
                                    <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-600 hover:text-gray-900">
                                        <UserIcon className="mr-2 w-4 h-4" />
                                        Clientes
                                    </Link>
                                </li>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <ChevronRightIcon className="mr-2 w-6 h-6 text-gray-400" />
                                        <span className="text-sm font-medium text-gray-500">Documentos Fiscais</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>

                        <div className="my-8 flex flex-row space-x-4 items-center truncate">
                            <img
                                className="object-contain h-10 w-10 rounded-full"
                                src={cliente?.arquivoLogoId ? `${API_URL}/arquivo/download/${cliente?.arquivoLogoId}` : require('../../assets/images/user.png')}
                                alt={"Foto usuario"}
                            />
                            <h3 className="text-lg leading-6 font-medium text-gray-900 flex-wrap items-end truncate">
                                <p className='truncate'>{cliente?.nomeFantasia}</p>
                                <p className='text-xs text-gray-600 truncate'>{cliente?.razaoSocial}</p>
                            </h3>
                        </div>

                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Tipo Perídodo
                                </label>
                                <Select
                                    isDisabled={loading}
                                    value={filtroPeriodo}
                                    onChange={filtro => {
                                        setFiltroPeriodo(filtro)
                                    }}
                                    options={opcoesPeriodo}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma opção disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Mês
                                </label>
                                <input
                                    defaultValue={mesSelecionado}
                                    onChange={e => {
                                        setMesSelecionado(e.target.value)
                                    }}
                                    type="month"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                            </div>

                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Tipo Documento
                                </label>
                                <Select
                                    isDisabled={loading}
                                    value={filtroTipoDocumento}
                                    onChange={filtro => setFiltroTipoDocumento(filtro)}
                                    options={opcoesTipoDocumento}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma opção disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Tipo Movimento
                                </label>
                                <Select
                                    isDisabled={loading}
                                    value={filtroTipoMovimento}
                                    onChange={filtro => setFiltroTipoMovimento(filtro)}
                                    options={opcoesTipoMovimento}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma opção disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <SolicitacaoDownloadListagem className="mx-0" />

                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Documentos Fiscais
                                        </h3>
                                    </div>
                                    {listaArquivoCliente.length > 0 && <div className="ml-4 mt-2 flex-shrink-0">
                                        <button
                                            type="button"
                                            onClick={handleDownloadTodos}
                                            disabled={(baixandoArquivo || baixandoTodos)}
                                            className={`${(baixandoArquivo || baixandoTodos) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                                        >
                                            {baixandoTodos && <Loading fill="white" className="mr-2 w-5 h-5" />}
                                            Baixar Todos
                                        </button>
                                    </div>}
                                </div>
                            </div>

                            <ul className="divide-y divide-gray-200">
                                {renderLinhasTabela()}
                            </ul>

                            <LayoutPaginacaoTabela
                                pagina={paginaAtual}
                                totalRegistros={totalRegistros}
                                registrosPorPagina={registrosPorPagina}
                                totalPaginas={totalPaginas}
                                onClickPaginaAnterior={() => {
                                    setPaginaAtual(paginaAtual - 1)
                                    carregaArquivoClientes(registrosPorPagina, paginaAtual - 1)
                                }}
                                onClickPaginaPosterior={() => {
                                    setPaginaAtual(paginaAtual + 1)
                                    carregaArquivoClientes(registrosPorPagina, paginaAtual + 1)
                                }}
                                onClickPagina={pagina => {
                                    setPaginaAtual(pagina)
                                    carregaArquivoClientes(registrosPorPagina, pagina)
                                }} />
                        </div>
                    </div>
                }
                <ModalConfirmacao
                    open={confirmacaoDownloadTodos}
                    setOpen={setConfirmacaoDownloadTodos}
                    titulo="Baixar todos os aquivos?"
                    textoBotao="Baixar Todos"
                    acaoBotao={downloadTodosAquivos}
                    alerta={false}
                >
                    <div className='pt-4 flex flex-row items-center space-x-4'>
                        <h3 className="text-3xl text-center leading-6 font-medium text-gray-900 flex-wrap items-end truncate">
                            {totalRegistros}
                            <p className='text-xs'>Arquivos</p>
                        </h3>
                        <div>
                            <p>{cliente?.razaoSocial}</p>
                            {filtroPeriodo.value &&
                                <div className='flex flex-row'>
                                    <p className='mr-1'>{filtroPeriodo.label}: </p>
                                    <p>{mesSelecionado.split("-")[1]}/{mesSelecionado.split("-")[0]}</p>
                                </div>
                            }
                            <p>{filtroTipoDocumento.value}</p>
                            <p>{filtroTipoMovimento.value}</p>
                        </div>
                    </div>
                </ModalConfirmacao>
            </>
        )
    }
}