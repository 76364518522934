import instance from '../configAxios';

export const getSolicitacaoDownloadById = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/solicitacaoDownload/${id}`)
    return { sucesso: response.status, data: response.data }
  } catch (error) {
    return { sucesso: false, mensagem: error.response.data };
  }
}

export const getSolicitacaoDownloadLogado = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/solicitacaoDownload/logado`)
    return { sucesso: response.status, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListSolicitacaoDownload = async () => {
  const axios = await instance(300000);

  try {
    const response = await axios.get(`/solicitacaoDownload/listagem`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      if (error.response.status === 400) {
        return {
          sucesso: false,
          mensagem: "Não possível salvar cadastro"
        }
      }
      
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListaSolicitacaoDownload = async (pesquisa) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/solicitacaoDownload/select?pesquisa=${pesquisa}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}