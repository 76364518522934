import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useNavigate, useLocation, Outlet, Link } from 'react-router-dom';

import { getUsuarioLogado } from "../../services/usuario";
import API_URL from '../../utils/api';
import { downloadArquivo } from '../../services/arquivo';
import { Switch, useDarkreader } from 'react-darkreader';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LayoutDashboard() {
    const theme = {
        brightness: 100,
        contrast: 100,
        grayscale: 0,
        sepia: false
    }
    const [isDark, { toggle }] = useDarkreader(false, theme);

    function handleDarkMode() {
        toggle();
        localStorage.setItem('@adminDarkMode', !isDark);
    }

    useEffect(() => {
        const darkMode = localStorage.getItem('@adminDarkMode');

        if (darkMode === 'true') {
            toggle();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const location = useLocation();
    let navigate = useNavigate();
    const [foto_url, setFotoUrl] = useState('')
    const [usuario, setUsuario] = useState({})

    async function carregaUsuario() {

        const response = await getUsuarioLogado()

        if (response.sucesso) {
            setUsuario(response.data)
            if (response.data.arquivoFoto) carregaFotoUsuario(response.data.arquivoFoto.id)
        }
    }

    async function carregaFotoUsuario(id) {
        const response = await downloadArquivo(id)

        if (response.sucesso) {
            const url = `${API_URL}/arquivo/download/${id}`
            setFotoUrl(url)
        }
    }

    useEffect(() => {
        carregaUsuario()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    function handleSair() {
        localStorage.removeItem('@admin_Token');
        navigate(`/login`)
    }

    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-gray-100">
        <body className="h-full">
        ```
      */}
            <div>
                <div className="flex flex-col">
                    <div className="sticky top-0 z-40 flex-shrink-0 flex h-16 bg-white shadow">
                        <div className="flex-1 px-4 flex justify-between">
                            <div className="flex-1 flex">
                                <div className="relative flex items-center justify-between h-16">

                                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                        <Link to="/" className="flex-shrink-0 flex flex-row items-center flex-wrap">
                                            <img
                                                className="h-8 object-contain"
                                                src="/logo.png"
                                                alt="Workflow"
                                            />
                                            {/* <img
                        className="h-5 mb-0.4 object-contain hidden sm:block"
                        src="/logo_nome.png"
                        alt="Workflow"
                      /> */}
                                        </Link>

                                    </div>
                                </div>

                                {/* <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </form> */}
                            </div>
                            <div className="ml-4 flex items-center md:ml-6">
                                {/* <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}
                                {/* Profile dropdown */}

                                <Switch checked={isDark} onChange={handleDarkMode} />

                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <span className="sr-only">Abrir menu</span>
                                            <h1 className='mr-4 font-semibold text-indigo-600 hidden sm:block'>{usuario?.nome}</h1>
                                            <img
                                                className="object-contain h-8 w-8 rounded-full"
                                                src={foto_url || require('../../assets/images/user.png')}
                                                alt={"usuário"}
                                            />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => handleSair()}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100' : '',
                                                            'block px-4 py-2 text-sm text-gray-700'
                                                        )}
                                                    >
                                                        Sair
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <main className="flex-1 bg-gray-100">
                        {/* <div className="py-6"> */}
                        <Outlet />
                        {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="py-4">
                  <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
                </div>
              </div> */}
                        {/* </div> */}
                    </main>
                </div>
            </div>
        </>
    )
}
