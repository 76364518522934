import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import Login from '../pages/Login'
import LayoutDashboard from "../components/LayoutDashboard";
import { autenticacao } from "../services/auth";
import ClienteListagem from "../pages/ClienteListagem";
import ArquivoClienteListagem from "../pages/ArquivoClienteListagem";

function Router() {
    async function verificaToken() {
        const token = localStorage.getItem('@admin_Token');

        if (!token && window.location.pathname !== "/login") {
            window.location.href = '/login';
        } else if (window.location.pathname !== "/login") {
            const response = await autenticacao()

            if (response.sucesso) {
                localStorage.setItem('@admin_Token', response.data.token);
            } else {
                localStorage.removeItem('@admin_Token');
                window.location.href = '/login';
            }
        }
    }

    useEffect(() => {
        (async () => {
            await verificaToken();
        }
        )();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const routes = [
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/",
            element: <LayoutDashboard />,
            children: [
                {
                    path: "/",
                    element: <ClienteListagem />,
                },
                {
                    path: "/Documentos-fiscais",
                    element: <ArquivoClienteListagem />,
                }
            ],
        },
    ];

    return useRoutes(routes);
}

export default Router;
