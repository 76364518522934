import instance from '../configAxios';

export const uploadArquivo = async (arquivo) => {
  const axios = await instance();

  try {
    const response = await axios.post(`/arquivo/`, arquivo)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const downloadArquivo = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/arquivo/download/${id}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getArquivo = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/Arquivo?idArquivo=${id}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}